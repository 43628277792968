
:root {
    --primary-color: #50c4d3;
  }

  .defaultWidth {
    max-width: calc(100vw - 36px);
    margin: 0 auto;
    width: 1260px;
  }

  .mainContainer {
    position: relative;
    overflow-x: hidden;
    background-color: white;
    isolation: isolate;
  }

  .mainContainer * {
    font-family: 'Inter', sans-serif;
  }

  .mainContainer::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle, rgba(80, 196, 211, 0.2) 0%, rgba(80, 196, 211, 0.2) 10%, rgba(80, 196, 211, 0) 30%);
    transform: translateX(-50%) translateY(-32%);
    z-index: -1;
  }

  .mainContainer::after {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle, rgba(80, 196, 211, 0.12) 0%, rgba(80, 196, 211, 0.12) 10%, rgba(80, 196, 211, 0) 30%);
    transform: translateX(20%) translateY(-32%);
    z-index: -1;
  }
  
  .btn {
    display: inline-block;
    border: none;
    background-color: var(--primary-color);
    color: white !important;
    padding: 7px 18px;
    border-radius: 8px;
    font-size: 16px;
  }

  .btn:hover {
    filter: brightness(110%);
  }

  .btnHeader {
    margin-left: 20px;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding: 12px 0;
  }

  .logo {
    height: 28px;
    width: auto;
  }

  .socialLinks {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .socialIcon {
    display: grid;
    place-content: center;
    width: 34px;
    height: 34px;
    background-color: #6b7380;
    border-radius: 5px;
  }

  .hero, .features, .characteristics, .process {
    margin-top: 100px;
  }

  .heroText {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    text-align: center;
  }

  .heroText>div {
    flex: 1;
  }

  .heroText h1 {
    font-size: 44px;
    font-weight: 700;
    color: #333;
    margin-bottom: 24px;
  }

  .heroText p {
    font-size: 18px;
    margin-bottom: 48px;
  }

  .heroText img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }

  .heroText .btn {
    font-size: 16px;
    padding: 10px 64px;
    width: 320px;
  }

  /* features */
  .features>div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
.featureText {
    max-width: 520px;
}
  .featureText .separator {
    margin: 50px 0;
    height: 1px;
    background-color: rgba(80, 196, 211, 0.3);
  }
  .features h2 {
    font-size: 74px;
    font-weight: 700;
    color: #333;
  }

  .features p {
    font-size: 18px;
  }

  .analysticImages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: -40px;
    border: none;
  }

  .analysticImages img {
    width: 100%;
  }

  .featureCard {
    background-color: #f8fafa;
    border-radius: 40px;
    padding: 20px;
  }

  .featureCard p {
    font-size: 18px;
    font-weight: medium;
    margin-top: 16px;
  }

  .featureCard>div {
    height: 400px;
  }

  .featureCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;
  }

  .featureLists h4 {
    font-size: 24px;
    margin-top: 12px;
  }

  .badgeContainer {
    display: flex;
    width: fit-content;
    gap: 12px;
    margin: 12px auto;
  }

  .badge {
    width: max-content;
    border: 1px solid #aaa;
    padding: 8px 12px;
    border-radius: 12px;
  }

  .processCard {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3,1fr);
  }

  .processCard>div {
    background-color: #f6fcfd;
    padding: 20px;
    border: 1px solid #d3f0f4;
    border-radius: 12px;
    color: #333;
  }

  .processCard .iconContainer {
    width: 80px;
    margin: 20px auto;
  }

  .processCard .iconContainer img {
    width: 100%;
    height: auto;
  }

  .processCard h3 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 22px;
  }

  .processCard p, .processCard li {
    color: #666;
    font-size: 16px;
    text-align: justify;
  }

  .process {
    margin-bottom: 80px;
  }

  .footer {
    border-top: 1px solid #eee;
    padding: 30px 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .footerSocialLink {
    display: flex;
    gap: 12px;
    justify-content: end;
  }

  .footerSocialLink a {
    display: grid;
    place-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 100vh;
  }

  .footerSocialLink i {
    font-size: 16px;
    color: #333;
  } 

  /* Responsive Design */
  @media (max-width: 768px) {
    .mainContainer::before, .mainContainer::after {
        content: none;
    }
    .features, .characteristics, .process {
        margin-top: 30px;
      }
    .btn {
        font-size: 12px;
    }
    .logo {
        height: 18px;
    }
    .socialIcon {
        display: none;
    }
    .heroText {
        flex-direction: column;
    }
    .heroText h1 {
        font-size: 28px;
    }
    .heroText p {
        font-size: 12px;
        margin-bottom: 24px;
    }
    .heroText .btn {
        width: fit-content;
    }
    .features>div {
        grid-template-columns: 1fr;
    }
    .features h2 {
        font-size: 34px;
        font-weight: 700;
        color: #333;
      }
      .featureText .separator{
        margin: 10px 0;
      }
      .features p {
        font-size: 14px;
      }
    
    .analysticImages {
        overflow: hidden;
        max-width: 420px;
        gap: 32px;
        border-top: 1px solid rgba(80, 196, 211, 0.3);
        border-bottom: 1px solid rgba(80, 196, 211, 0.3);
        padding-bottom: 20px;
        margin-top: -10px;
    }

    .cardContainer {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      .featureCard {
        border-radius: 30px;
        padding: 8px;
      }
    
      .featureCard p {
        font-size: 14px;
        font-weight: medium;
        margin-top: 16px;
      }
    
      .featureCard>div {
        height: 180px;
      }
    
      .featureCard img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }
      .featureLists {
        grid-column: span 2;
        margin-top: 24px;
      }
      .processCard {
        grid-template-columns: 1fr;
      }
      .processCard h3 {
        margin-bottom: 20px;
        font-size: 18px;
      }
    
      .processCard p, .processCard li {
        color: #666;
        font-size: 14px;
        text-align: justify;
      }
      .processCard .iconContainer {
        width: 50px;
        margin-top: 0;
      }
      .footerSocialLink {
        display: flex;
        gap: 12px;
        justify-content: center;
      }

      .footer {
        flex-direction: column;
        padding: 12px 0;
      }
  }