.modalContainer * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.modalContainer {
    position: fixed;
    inset: 0;
    z-index: 1000;
}

.modalOverlay {
position: absolute;
z-index: 1;
inset: 0;
background-color: rgba(0, 0, 0, 0.5);
}

.modalOverlay.modalAppear {
    animation: appear 0.3s ease-out forwards;
}

.modalContent {
    background-color: white;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
}

.modalContent.modalAppear {
    animation: appear 0.3s ease-out forwards;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eee;
    padding: 12px 12px 12px 24px;
}

.modalTitle {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
}

.modalClose {
    border: none;
}

.modalClose i {
    font-size: 28px;
}

.modalBody {
    padding: 24px;

}

.modalBody button {
    display: block;
    background-color: #50c4d3;
    margin: 20px auto 0;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 0;
    width: 200px;
    font-size: 14px;
}

.modalBody button:hover {
    filter: brightness(105%);
}

.modalBody ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;
}

.modalBody ol li {
    position: relative;
    text-align: justify;
    font-size: 14px;
    padding-left: 45px; /* Create space for the bordered number */
    margin-bottom: 13px; /* Space between items */
    line-height: 1.5;
}

.modalBody ol li::before {
    counter-increment: item; /* Increment the counter */
    content: counter(item); /* Display the counter value */
    
    /* Styling for the bordered number */
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8em;
    height: 1.8em;
    background-color: #ffffff;
    color: #50c4d3;
    border: 1px solid #50c4d3; /* Border around the number */
    border-radius: 4px; /* Makes it circular - remove for square borders */
    font-weight: bold;
    margin-right: 12px;
  }

  @media screen and (max-width: 990px) {
    .modalTitle {
        font-size: 14px;
    }
    .modalBody {
        padding: 16px;
    }
    .modalBody button {
        width: 160px;
        padding-block: 6px;
    }
    .modalBody ol li {
        font-size: 12px;
        padding-left: 35px; /* Create space for the bordered number */
        line-height: 1.3;
    }
  }