// Variables

$welcome-font-18: 18px;
$welcome-font-14: 14px;

.welcome *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.welcome {
    --bg-theme-color-dark: #462300;
    --bg-theme-color-darker: #6f3800;
    --bg-theme-color-normal: #ffe8d1;
    --bg-theme-color-lighter: #ffedda;
    --bg-theme-color-light: #fffdfa;
    background: var(--bg-theme-color-normal);
}

.welcome[data-clr="blue"] {
    --bg-theme-color-dark: #00384d;
    --bg-theme-color-darker: #005e80;
    --bg-theme-color-normal: #c0efff;
    --bg-theme-color-lighter: #d9f5ff;
    --bg-theme-color-light: #f2fcff;
}

.welcome[data-clr="teal"] {
    --bg-theme-color-dark: #26303d;
    --bg-theme-color-darker: #323f51;
    --bg-theme-color-normal: #dde3ea;
    --bg-theme-color-lighter: #e9ecf1;
    --bg-theme-color-light: #f4f6f8;
}

.welcome-top{
    height: 70%;
    background-color: var(--bg-theme-color-normal);
    position: relative;
}

.welcome-order{
    width: 40%;
}

.welcome-header {
    background: var(--bg-theme-color-light);
    height: 40px;
    display: flex;
    align-items: center;
}

.welcome-footer-bg {
    height: 60px;
    background-color: var(--bg-theme-color-dark);
    display: flex;
    align-items: center;
}

.welcome-footer-bg>div,
.welcome-header>div{
    width: 100%;
}

.welcome-branch-box {
    background-color: white;
    border-bottom: 1px solid var(--bg-theme-color-lighter);
}

.welcome-branch-box-icon {
    color: var(--bg-theme-color-darker);
}

.welcome-warning-text {
    color: var(--bg-theme-color-dark)
}

.welcome-theme-text-color {
    color: var(--bg-theme-color-dark);
}

.welcome-company-name {
    font-size: 36px;
    color: var(--bg-theme-color-dark);
    font-weight: 700;
    line-height: 1.2;
}

.welcome-company-address {
    font-size: $welcome-font-14;
    padding-bottom: 20px;
}

.welcome-company-img{
    height: 420px;
    width: 100%;
    background-position: top;
    background-size: contain;
    // margin-right: 5%;
}

.welcome-booking-btn{
    background-color: var(--bg-theme-color-darker);
    padding: 10px;
    color: white;
    border-radius: 20px;
    font-size: $welcome-font-18;
    text-align: center;
    width: 100%;
    max-width: 300px;
    position: relative;
    isolation: isolate;
    &::after{
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 50%;
        // z-index: -1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background: linear-gradient(to bottom, #fff2, #ffffff02);
    }
    &:hover {
        background-color: var(--bg-theme-color-darker) !important;
        color: white !important;
        filter: brightness(110%);
    }
}

.welcome-swiper-container {
    width: 65%;
    border: 1px solid #ddd;
    border-radius: 10px;
    // height: 100%;
}
.welcome-swiper-container>div,
.welcome-swiper-container>div>div,
.welcome-swiper-container>div>div>div,
.welcome-swiper-container>div>div>div>ul
 {
    height: 100%;
}

.welcome-swiper-container>div>div>div{
    border-radius: 10px;
    overflow: hidden;
}

.welcome-swiper-container>div>div>div>ul{
    transition: all 0.6s !important;
}

.welcome-swiper-container .carousel .control-dots .dot{
    background: blue !important;
}

.welcome-swiper-container .carousel .control-next.control-arrow::before,
.welcome-swiper-container .carousel .control-prev.control-arrow::before{
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}
.welcome-swiper-container .carousel .control-next.control-arrow::before{
  border-left: 8px solid #00f;
}
.welcome-swiper-container .carousel .control-prev.control-arrow::before{
  border-right: 8px solid #00f;
}
.welcome-img{
  width: 100%;
  height: 100%;
  // min-height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.welcome-aboutus {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
}


.welcom-sideMenu {
    width: 35%;
    margin-left: 20px;
    padding-inline: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
}

.welcome-about{
    background-color: var(--bg-theme-color-lighter);
    justify-content: space-around;
    height: 100%;
}
.welcome-about-us{
    font-size: $welcome-font-18;
    display: inline-block;
    position: relative;
}
.welcome-about-us-content{
    font-size: $welcome-font-14;
    color: #222222;
}

.welcome-shape{
    height: 1px;
    margin: 0 auto 20px;
    position: relative;
    width: 60px;
    background-color: #36d3d3;
}

.welcome-about-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.welcome-contact{
    display: flex;
    justify-content: space-around;
    padding: 45px;
    width: 85%;
    margin: 0 auto;
}
#schedule, #contact {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: inherit;
}
.section-pd {
    padding: 15px 40px;
}
#contacts ul{
    padding: 0;
}
#contacts li {
    margin-bottom: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

#contacts li .icon {
    font-size: 16px;
    float: left;
    width: 15px;
}
#contacts li .links {
    font-size: 16px;
    padding-left: 10px;
    overflow: hidden;
}

.onlinebooking-header {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.033);
}

.booking-body-body {
    padding-block: 40px;
}

@include media-breakpoint-down(xl) {
    .welcome-order{
        width: 100%;
    }
    .welcome-contact{
        display: block;
    }
}
  
 .welcome .wave{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
 }
 .welcome .wave1{
    position: absolute;
    left: 0;
    bottom: 0.5px;
    width: 100%;
    transform-origin: bottom;
    transform: rotate(180deg);
 }

 .welcome .wave .box,
 .welcome .wave1 .box
  {
    position: relative;
    z-index: 1;
    // 46, 38, 52
    --size: 32px;
    --p: 52px;
    --R: 110.9px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
    
    height: 12px;
    // margin: 10px 0;
    
    // --size: 48px;
    // --p: 50px;
    // --R: 59.9px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
    
    // height: 72px;

    -webkit-mask:
      radial-gradient(var(--R) at left 50% var(--d,top) calc(var(--size) + var(--p)),#000 99%,#0000 101%) 
        calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
      radial-gradient(var(--R) at left 50% var(--d,top) calc(-1*var(--p)),#0000 99%,#000 101%) 
        left 50% var(--d,top) var(--size)/calc(4*var(--size)) 100% repeat-x;
        // fd8e8c
        // fdd7b5
        border: none;
    }
    
.welcome .wave .box {
      background:linear-gradient(90deg, var(--bg-theme-color-normal), var(--bg-theme-color-lighter));  
  }
.welcome .wave1 .box {
      background:linear-gradient(90deg, var(--bg-theme-color-lighter), var(--bg-theme-color-normal));  
  }

  
//   .welcome .box + .box {
//     --d: bottom;
//   }

@include media-breakpoint-down(md) {
    .welcome {
        padding-inline: 14px;
    }
    .welcome-img{
        min-height: 300px;
        // height: 300px;
        background-size: cover;
        margin-inline: auto;
    }
    .welcome-company-name {
        font-size: 28px;
    }
    .welcome-swiper-container {
        width: 100%;
        // height: 300px;
    }
    .onlinebooking-header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }

.booking-body-body {
    padding-top: 100px;
}

    .welcom-sideMenu {
        width: 100%;
        padding-inline: 20px;
        margin-top: 20px;
        margin-left: 0px;
    }
    // .welcome-booking-btn{
    //     max-width: 100%;
    // }
    // .box {
    //     --size: 42px;
    //     --p: 42px;
    //     --R: 50px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
        
    //     height: 62px;
    // }
    .welcome-company-img{
        height: 340px;
        max-width: 95vw;
        background-position: top;
        // margin-left: 10%;
        // width: 75%;
    }
    .welcome-top{
        height: auto;
    }
}

.custom-menu-hover{
    &:hover{
        .custom-menu-text{
            color: var(--kt-text-primary) !important;
        }
    }   
}

