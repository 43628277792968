.parentContainer * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.parentContainer {
    container-type: inline-size;
    container-name: grid-parent;
    width: 100%;
}
.mainContainer {
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.membershipContainer {
    padding-right: 30px;
}

.membershipBtnContainer{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 12px;
}
.membershipButton {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 16px;
    color: #666;
}

.membershipButton.active {
    position: relative;
    border-color: #50c4d3;
    box-shadow: 0 1px 2px #b7d1d4c3;
    color: #111;
}

.membershipButton.active::after {
    content: url('../../../../../../public/c9/check.svg');
    position: absolute;
    right: 8px;
    top: 11px;
    display: block;
    color: #50c4d3;
}

.couponInput {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px 16px;
    width: 100%;
}

.couponInput:focus {
    outline: 1px solid #50c4d3;
    outline-offset: -1px;
}

.couponButton {
    border: none;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 16px;
    width: 124px;
    color: #fff;
    font-weight: bold;
    background: #50c4d3;
}

.couponButton:disabled {
    background: #ddd;
    color: #666;
}

.couponButton:hover {
    filter: brightness(105%);
}

.paymentInfo {
    border-left: 1px dashed #ddd;
    border-top: none;
    padding-left: 30px;
    margin-top: 0px;
}

.paymentInfoContainer {
    display: grid;
    gap: 4px;
    margin-top: 20px;
}

.paymentInfoContainer>div:nth-child(1),
.paymentInfoContainer>div:nth-child(2),
.paymentInfoContainer>div:nth-child(3) {
    display: flex;
    justify-content: space-between;
}

.paymentInfoContainer>div:nth-child(1) {
    color: #666;
}

.paymentInfoContainer>div:nth-child(2) {
    color: #50c4d3;
}

.paymentInfoContainer>div:nth-child(3) {
    color: #111;
    font-weight: bold;
}

.paymentInfo button {
    border: none;
    width: 100%;
    margin-top: 16px;
    border-radius: 6px;
    padding: 8px 16px;
    color: #fff;
    background: #50c4d3;
    font-weight: bold;
}

.paymentInfo button:hover {
    filter: brightness(105%);
}

.pendingBtn {
    width: 24px;
    margin: 0 auto;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 5px solid #fff;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1{
     0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
     12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
     25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
     50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
     100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
  }
  @keyframes l20-2{ 
    0%    {transform:scaleY(1)  rotate(0deg)}
    49.99%{transform:scaleY(1)  rotate(135deg)}
    50%   {transform:scaleY(-1) rotate(0deg)}
    100%  {transform:scaleY(-1) rotate(-135deg)}
  }

  .noteContainer {
    margin-top: 32px;
    border-top: 1px dashed #ddd;
    padding-top: 12px;
    font-size: 12px;
  }
.noteContainer h5 {
    margin-bottom: 12px;
}
.noteContainer ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;
}

.noteContainer ol li {
    position: relative;
    padding-left: 35px; /* Create space for the bordered number */
    margin-bottom: 13px; /* Space between items */
}

.noteContainer ol li::before {
    counter-increment: item; /* Increment the counter */
    content: counter(item); /* Display the counter value */
    
    /* Styling for the bordered number */
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8em;
    height: 1.8em;
    background-color: #ffffff;
    color: #50c4d3;
    border: 1px solid #50c4d3; /* Border around the number */
    border-radius: 4px; /* Makes it circular - remove for square borders */
    font-weight: bold;
    margin-right: 12px;
  }

@container grid-parent (max-width: 600px) {
    .mainContainer {
        grid-template-columns: 1fr;
    }
    .membershipContainer {
        padding-right: 0;
    }
    .paymentInfo {
        border-left: none;
        border-top: 1px dashed #ddd;
        padding-left: 0;
        margin-top: 24px;
        padding-top: 14px;
    }
  }
